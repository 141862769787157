import React, {useState} from "react";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Container, Row, Col ,Card} from "react-bootstrap";
import Header from "./Header.jsx";
import Sidebar from "./Sidebar.jsx";
import FormContainer from "./FormContainer.jsx";
import QueryContainer from "./QueryContainer.jsx";
import './Layout.css'

// Lays out the Sidebar and form area.  Calls FormContainer which loads required
// data for the item selected by SideBar

const Layout = (props) => {

    //  Name the default blank screen, then callss FormConatain that renders forms based on Sidebar selections
    const [formName, setFormName] = useState('UE Business Application');

    //  Used throughout all forms and utilities to cuase database refresh
    const [refresh, setRefresh] = useState(false)

    return (
        <>
        <ToastContainer
            position="bottom-left"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        <Header/>
        <Container fluid >
            <Row className='d-flex justify-content-center'>
                <Col md={2} className="sidebar-wrapper">
                    <Sidebar
                    setFormName={setFormName}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    roleId={props.roleId}
                    // setRoleId={setRoleId}
                    />
                </Col>
                <Col md={8}  >
                    {(formName === "UE Business Application") &&
                    <div className = "banner ">
                        <h1 >Utopia Experiences Business Application</h1>
                    </div>
                    }
                    {(formName !== "UE Business Application") &&
                    <Card  className = "card-style" >
                        <Card.Title className="form-title"
                            ><h1>{formName}</h1>
                        </Card.Title>
                        <br/>

                        {/* for simple data entry forms that don't require complex filtered queries */}

                        <Card.Body className = "card-body-style"  >
                        {(  formName === "Client Pricing Plans"
                         || formName === "Pricing Plan Types"
                         || formName === "Sponsored Host Information"
                         || formName === "Timezones"
                         || formName === "Training Assessments"
                         || formName === "Training Videos"
                         || formName === "Zoom Licenses"
                         ) &&
                            <FormContainer
                                refresh={refresh}
                                setRefresh={setRefresh}
                                formName={formName}
                            />
                        }

                        {/* for more advanced / complex filtering and data export requirements */}

                        {( formName === "Client Billing Master"
                        || formName === "Host Charges Review"
                        || formName === "Sponsored Host Game Schedule"
                        || formName === "Sponsored Host Payables"
                        || formName === "Sponsored Host Ratings"
                        || formName === "User Activity") &&
                            <QueryContainer
                                refresh={refresh}
                                setRefresh={setRefresh}
                                formName={formName}
                                />
                        }
                        </Card.Body>
                    </Card>
                    }
                </Col>
            </Row>

            </Container>
        </>
        );
  };

  export default Layout