import React, { useState,} from "react";
import {Container, Button, Spinner} from "react-bootstrap";
import SelectBar from "./SelectBar"
import ModalContainer from "./ModalContainer";
import HostTable from "./HostTable"
import PricingPlanTable from "./PricingPlanTable";
import PricingPlanTypeTable from "./PricingPlanTypeTable";
import TimezoneTable from "./TimezoneTable";
import TrainingAssessmentTable from "./TrainingAssessmentTable";
import TrainingVideoTable from "./TrainingVideoTable";
import ZoomTable from "./ZoomTable"
import GetData from "../utils/GetData"
import "./Form.css"

// Runs queries against the database.  Calls the SelectBar, and ModalContainer for popup to display
// CRUD forms, which do the data entry work.  Also displays a table of query results for selection
// which will also take the user to ModalContainer and CRUD forms.

const FormContainer = (props) => {


  console.log ("in FormContainer")
  let requestPath = ''
  let caller = ''
  let addButtonText = ''
  const REACT_APP_ENDPOINT_PRICING=process.env.REACT_APP_ENDPOINT_PRICING;
  const REACT_APP_ENDPOINT_ZOOM=process.env.REACT_APP_ENDPOINT_ZOOM;
  const REACT_APP_ENDPOINT_HOST=process.env.REACT_APP_ENDPOINT_HOST;
  const REACT_APP_ENDPOINT_TRAINING_MAINT=process.env.REACT_APP_ENDPOINT_TRAINING_MAINT;
  const REACT_APP_ENDPOINT_TIMEZONES=process.env.REACT_APP_ENDPOINT_TIMEZONES;

  if (props.formName === "Client Pricing Plans") {
    requestPath = REACT_APP_ENDPOINT_PRICING + "/pricing_plans"
    addButtonText = "Add Pricing Plan"
  }
  else if (props.formName === "Pricing Plan Types") {
    requestPath = REACT_APP_ENDPOINT_PRICING + "/pricing_plan_types"
    addButtonText = "Add Plan Type"
  }
  else if (props.formName === "Sponsored Host Information") {
    requestPath = REACT_APP_ENDPOINT_HOST + "/hosts"
    addButtonText = "Add Host"
  }
  else if (props.formName === "Timezones") {
    requestPath = REACT_APP_ENDPOINT_TIMEZONES + "/timezones"
    console.log("requestPath=", requestPath)
    addButtonText = "Add Timezone"
  }
  else if (props.formName === "Training Assessments") {
    requestPath = REACT_APP_ENDPOINT_TRAINING_MAINT + "/assessments"
    addButtonText = "Add Training Assessment"
  }
  else if (props.formName === "Training Videos") {
    requestPath = REACT_APP_ENDPOINT_TRAINING_MAINT + "/training_videos"
    addButtonText = "Add Training Video"
  }
  else if (props.formName === "Zoom Licenses") {
    requestPath = REACT_APP_ENDPOINT_ZOOM + "/zoom_licenses"
    addButtonText = "Add License"
  }
  caller=props.formName


  let refresh = props.refresh

  console.log("FormContainer refresh=", refresh)
  const [getDataComplete, setGetDataComplete] = useState(false);

  const formData = GetData(requestPath, refresh, setGetDataComplete)
  console.log ("FormContainer formData =", formData)

  // reset refresh value to prevent unnecessary queries.  After first query, only rerenders with with a
  // reset refresh value will prompt a query

  // props.setRefresh(false)

  // set values for call to ModalContainer

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (


    <Container fluid >

    {/* ModalContainer is used to display all CRUD forms.  Called here for adding records */}

    <ModalContainer
            show={show}
            handleClose={handleClose}
            caller={caller}
            formData={formData}
            CRUD={"Add"}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />

    <div className="d-flex align-items-center justify-content-center button-box">
    <div><br/></div>

    <Button className="blue-button"
        onClick={() => {
          console.log("click from FormContainer")
          setShow(true)
        }}
    >{addButtonText}</Button>

    </div>

    {(!getDataComplete) &&
        <div >
          <Spinner animation="grow" size="sm" role="status" style={{color: "#00447c"}}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      }

    {/* SelectBar also calls ModalContainer for Read/UpdateDelete after users select item to edit.
      some callers might not want it */}

    {(props.formName !== "Client Pricing Plans") &&
        <SelectBar
            caller={caller}
            formData={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
     {/* Tables also calls ModalContainer for Read/UpdateDelete after users select item to edit */}

     {(props.formName === "Client Pricing Plans") &&
        <PricingPlanTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }

     {(props.formName === "Pricing Plan Types") &&
        <PricingPlanTypeTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
    {(props.formName === "Sponsored Host Information") &&
        <HostTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
    {(props.formName === "Timezones") &&
        <TimezoneTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
    {(props.formName === "Training Assessments") &&
        <TrainingAssessmentTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
    {(props.formName === "Training Videos") &&
        <TrainingVideoTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
    {(props.formName === "Zoom Licenses") &&
        <ZoomTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }

    </Container>
  );
}

export default FormContainer