import React, { useState, useMemo} from "react";
import styled from 'styled-components'
import QueryTable from "./QueryTable";

const PricingPlanTable = (props) => {

    const [show, setShow] = useState(false);
    const [valueSelected, setValueSelected] = useState({})
    const [modalType, setModalType] = useState()
    const [CRUD, setCRUD] = useState()

    // preprocessing

    for (let i = 0; i < props.data.length; i++)  {
        if (props.data[i].game_name === null) {
            props.data[i].game_name = "**********"
        }
    }

    // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;

        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                ${'' /* border-bottom: 0; */}
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }


          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }
        }
      `

  // apply fixed format to host average_rating

  function toFixedFormat (value) {
    return parseFloat(isNaN(value) ? 0 : value).toFixed(2)
  }

  // simple array to map columns for table.


  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [
          {
            Header: "Plan Type",
            accessor: "pricing_plan_type",
            width: 160,
          },
          {
            Header: "Plan Name",
            accessor: "plan_name",
            width: 200,
          },
          {
            Header: "Description",
            accessor: "plan_description",
            width: 467,
          },
          {
            Header: "Game",
            accessor: "game_name",
          },
          {
            Header: "Frequency",
            accessor: "billing_frequency",
            width: 100,
          },
          {
            Header: "Charge",
            accessor: "per_billing_charge",
            width: 90,
            Cell: ({row}) => {
            const dataValue = row.original
            return (
                <div  style={{ textAlign: "right" }}> {toFixedFormat(dataValue.per_billing_charge)} </div>
            )
            }
          },
        ]
      },
    ],
    []
  );
  return (
      <Wrapper>
        <Styles>
            <QueryTable
              columns={columns}
              data={props.data}
              caller={props.caller}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
              setShow={setShow}
              show={show}
              valueSelected={valueSelected}
              setValueSelected={setValueSelected}
              modalType={modalType}
               />
        </Styles>
    </Wrapper>
  );
}

export default PricingPlanTable;