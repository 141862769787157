import React, {useState} from "react"
import {Modal, Button} from "react-bootstrap"
import AssessmentFormCRUD from "./AssessmentFormCRUD"
import ClientBillingFormCRUD from "./ClientBillingFormCRUD"
import ClientBillingMasterExport from "./ClientBillingMasterExport"
import HostFormCRUD from "./HostFormCRUD"
import PricingPlanFormCRUD from "./PricingPlanFormCRUD"
import PricingPlanTypeFormCRUD from "./PricingPlanTypeFormCRUD"
import TimezoneFormCRUD from "./TimezoneFormCRUD"
import TimezoneUpdateForm from "./TimezoneUpdateForm"
import TrainingFormCRUD from "./TrainingFormCRUD"
import ZoomFormCRUD from "./ZoomFormCRUD"
import "./ModalContainer.css"

const ModalContainer = (props) => {

    // set values for call to  inner data check modal
    const [showInner, setShowInner] = useState(false);
    const handleCloseInner = () => setShowInner(false);

    // conditional to create full screen modal for some forms
    let fullScreen = false
    if (props.caller === "Client Billing Master" && props.CRUD === "Export") {
        fullScreen = true
    }

    console.log ("***********in ModalContainer")
    console.log ("ModalContainer refresh= ", props.refresh)
    console.log ("ModalContainer formData=", props.formData)
    console.log ("ModalContainer props.show= ", props.show)
    console.log ("ModalContainer props.selectedValue=", props.selectedValue)
    console.log ("ModalConainer caller=", props.caller)
    console.log ("ModalConainer CRUD=", props.CRUD)


    // state variable and function to pass to forms to track form data changes
    const [dataChange, setDataChange] = useState("false")
    // console.log ("ModalContainer dataChange= ", dataChange)

    console.log ("ModalContainer dataChange= ", dataChange)

    let displayTitle = ''

    if (props.CRUD === "ReadUpdateDelete") {
        displayTitle = "View / Edit " + props.caller
    }
    else if (props.CRUD === "Add") {
        displayTitle = "Add " + props.caller
    }
    else if (props.CRUD === "System Admin") {
        displayTitle = props.caller
    }
    // this is a bit kludgy, but we're now using ModalContainer in a couple of differnt ways...
    else if (props.caller === "Client Billing Master" && props.CRUD === "Export") {
        displayTitle = "Client Billing Master Export"
    }


    function checkExit (dataChange, handleClose) {
        if (dataChange === true ) {
        setShowInner(true)
        }
        else {
            handleClose()
        }
        setDataChange(false)
    }

    return (
    <>

    {/* this is an inner modal that alerts the user that they might want to save their data */}

    <Modal contentClassName="popup-content"
        show={showInner}
        onHide={handleCloseInner}
        size="lg"
        centered="true"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        >
        <Modal.Header closeButton className="yellow-header"></Modal.Header>
        <Modal.Title className = "modal-title" >You've entered some data</Modal.Title>
        <div>
        <br/>
        <p className="modal-text">Are you sure you want to leave without updating the database?</p>
        </div>
        <Modal.Footer style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
        }}>
        <Button className="blue-button"
            onClick={() => {
                props.handleClose()
                handleCloseInner()
                }}
            >
            Yes
        </Button>
        <Button className="blue-button"
            onClick={() => {
                setDataChange(true)
                handleCloseInner()
                }}
            >
            No
        </Button>
        </Modal.Footer>
    </Modal>

    {/* this is the 'main' modal that serves as a container for CRUD forms */}

    <Modal contentClassName="popup-content"
        show={props.show}
        onHide={props.handleClose}
        fullscreen={fullScreen}
        size="xl"
        centered="true"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        >
        <Modal.Header className="justify-content-end modal-header border-0" >
        <Button className="blue-modal-button"
            onClick={() => {
                if (dataChange === false) {
                    window.localStorage.clear()  //clean up for the Host preview kludge
                    props.handleClose()
                    // toggle to rerender main to initiaite a new query
                    if (props.refresh === false) {
                            props.setRefresh(true)
                    }
                    else {
                        props.setRefresh(false)
                    }
                    // setDataChange(false)
                }
                window.localStorage.clear()  //clean up for the Host preview kludge
                checkExit(dataChange, props.handleClose)
                }}
                >X</Button>
        </Modal.Header>
        <Modal.Title className = "modal-title" >{displayTitle}</Modal.Title>
        <br/>
        <Modal.Body>

        {(props.caller ==="Client Billing Master" && props.CRUD !== "Export") &&
          <ClientBillingFormCRUD
            communityId={props.selectedValue}
            formData={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        {(props.caller ==="Client Billing Master" && props.CRUD === "Export") &&
          <ClientBillingMasterExport
            communityId={props.selectedValue}
            data={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        {(props.caller ==="Client Pricing Plans") &&
          <PricingPlanFormCRUD
            prcingPlanTypeId={props.selectedValue}
            formData={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        {(props.caller ==="Pricing Plan Types") &&
          <PricingPlanTypeFormCRUD
            prcingPlanTypeId={props.selectedValue}
            formData={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        {(props.caller ==="Sponsored Host Information") &&
          <HostFormCRUD
            hostUserId={props.selectedValue}
            formData={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        {(props.caller ==="System Timezone Updates") &&
          <TimezoneUpdateForm
            hostUserId={props.selectedValue}
            formData={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        {(props.caller ==="Timezones") &&
          <TimezoneFormCRUD
            masterTimezoneId={props.selectedValue}
            formData={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        {(props.caller ==="Training Assessments") &&
          <AssessmentFormCRUD
            assessmentId={props.selectedValue}
            formData={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        {(props.caller ==="Training Videos") &&
          <TrainingFormCRUD
            trainingId={props.selectedValue}
            formData={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        {(props.caller ==="Zoom Licenses") &&
          <ZoomFormCRUD
            zoomAccountUserId={props.selectedValue}
            formData={props.formData}
            CRUD={props.CRUD}
            dataChange={dataChange}
            setDataChange={setDataChange}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            handleOuterClose={props.handleClose}
            />
        }

        </Modal.Body>
        <Modal.Footer className="modal-footer border-0 ">
            <Button className="blue-button"
                onClick={() => {
                if (dataChange === false) {
                    window.localStorage.clear()  //clean up for the Host preview kludge
                    props.handleClose()
                    // toggle to rerender main to initiaite a new query
                    if (props.refresh === false) {
                            props.setRefresh(true)
                        }
                    else {
                        props.setRefresh(false)
                    }
                }
                window.localStorage.clear()  //clean up for the Host preview kludge
                checkExit(dataChange, props.handleClose)
                }}
                >
                Close
                </Button>
        </Modal.Footer>
    </Modal>
    </>

)

}

export default ModalContainer