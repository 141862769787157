import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Button, Stack, Spinner} from "react-bootstrap"
import MessageModal from "./MessageModal"
import doValidations from "../utils/doValidations";
import ValidationQuery from "../utils/ValidationQuery"
import WriteData from "../utils/WriteData";
import "./Form.css"

const PricingPlanTypeFormCRUD = (props) => {

    console.log("in PricingPlanTypeCRUD")
    const setDataChange = props.setDataChange // descructure for useEffect
    // console.log("props.setRefresh=", props.setRefresh)
    console.log("PricingPlanTypeCRUD formData= ", props.formData)
    console.log("PricingPlanTypeCRUD pricingPlanTypeId = ", props.prcingPlanTypeId)

    const REACT_APP_ENDPOINT_PRICING=process.env.REACT_APP_ENDPOINT_PRICING;
    let apiPath = ''

    const [dbWorkComplete, setDbWorkComplete] = useState(true);
    const [postDelete, setPostDelete] = useState(false);  //to prevent a preLoadValues load in rerender after record deletion.

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [titleMessage, setTitleMessage] = useState(null)
    const [validationMessage, setValidationMessage] = useState(null)

    // const [deleteError, setDeleteError] = useState(false)
    const [saveWarning, setSaveWarning] = useState(false)
    // const [validationLookup, setValidationLookup] = useState(false)

    let planTypeUseCount = 0
    planTypeUseCount = ValidationQuery('/pricing_plan_type_use_count', props.prcingPlanTypeId, "timezones")
    console.log ("*****planTypeUseCount=", planTypeUseCount)

    let preloadValues = {}

    const onSubmit = async data => {
        console.log(data);
        setDbWorkComplete(false)
        console.log ("formAction=", getValues("formAction"))
        let passValidation = true
        if (getValues("formAction") === "insert" || getValues("formAction") === "update") {
            passValidation = await doValidations("PricingPlanTypeCRUD", getValues("formAction"), dirtyFields, data, props.formData, setValidationMessage)
        }
        if (passValidation) {
            if (getValues("formAction") === "insert") {
                apiPath=REACT_APP_ENDPOINT_PRICING + "/pricing_plan_type_insert"
                WriteData(apiPath, data, "post", setDbWorkComplete)
            }
            else if (getValues("formAction") === "update") {
                apiPath=REACT_APP_ENDPOINT_PRICING + "/pricing_plan_type_update"
                WriteData(apiPath, data, "put", setDbWorkComplete, getValues("pricingPlanTypeId"), props.refresh, props.setRefresh)
                props.handleOuterClose()
            }
            else if (getValues("formAction") === "delete") {
                apiPath=REACT_APP_ENDPOINT_PRICING + "/pricing_plan_type_update"
                WriteData(apiPath, data, "delete", setDbWorkComplete, getValues("pricingPlanTypeId"),  props.refresh, props.setRefresh)
                setPostDelete(true)
                setDbWorkComplete(true)
                props.handleOuterClose()
            }
            if (dbWorkComplete) {
                console.log ("back in PricingPlanTypeCRUD dbWorkComplete")
                reset({
                    pricingPlanTypeId: "",
                    pricingPlanType: "",
                    pricingPlanTypeDesc: "",
                    },
                    {
                        keepDefaultValue: true,
                    })
                props.setDataChange(false)

             }
        }
        else {
            console.log('validationMessage=', validationMessage)
            setTitleMessage("There's a data issue")
            setDbWorkComplete(true)
            setShow(true)
        }
    }

    // console.log("in to PricingPlanTypeCRUD dataChange= ", props.dataChange)

    if (props.CRUD === "ReadUpdateDelete" && !postDelete) {
         let formValues = props.formData.filter(obj => {
                return obj.pricing_plan_type_id === props.prcingPlanTypeId
        })
        console.log("PricingPlanTypeCRUD formValue= ", formValues)
        preloadValues = {
            pricingPlanTypeId: formValues[0].pricing_plan_type_id,
            pricingPlanType: formValues[0].pricing_plan_type,
            pricingPlanTypeDesc: formValues[0].pricing_plan_type_desc,
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
    }
    else {
        preloadValues = {
            pricingPlanTypeId: "",
            pricingPlanType: "",
            pricingPlanTypeDesc: "",
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
        }

    const { register, handleSubmit, watch, getValues, setValue, reset, formState: {dirtyFields}} = useForm({
        defaultValues: preloadValues
    });

    // watch tracks user input
    watch ()

    useEffect(() => {
        const subscription = watch(() => setDataChange(true))
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
    <div className="form-box">
    <Container fluid className="form-box">
        <MessageModal
            show={show}
            handleClose={handleClose}
            titleMessage={titleMessage}
            validationMessage={validationMessage}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
            {(planTypeUseCount > 0) &&
                <Container fluid>
                <Row display="table" className="align-items-center justify-content-center">
                    <Col md="auto" className="orange-text" >In Use</Col>
                </Row>
                <br/><br/>
                </Container>
            }
            <Row>
            <Col >
                <label className="label-format">Pricing Plan Type*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Pricing plan type name" className="input-format"
                {...register("pricingPlanType", {required: true})} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Description*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Pricing plan type description" className="input-format"
                {...register("pricingPlanTypeDesc", {required: true})} />
            </Col>
            </Row>
            <br/>


            {/* conditionally render CRUD buttons based on form mode. */}

            <Row className="justify-content-center">

                    <>
                    <Stack direction="horizontal" gap={3} className="d-flex justify-content-center">

                    {(props.CRUD === "ReadUpdateDelete") &&
                    <>
                    <Button className="blue-button"
                        onClick={() => {
                            if (saveWarning === false && planTypeUseCount > 0) {
                                setTitleMessage ("You are about to update a Pricing Plan Type")
                                setValidationMessage("The number of pricing plans referencing this type is: " + planTypeUseCount + ".  "
                                + "Please be certain you want to perform this action.  Press Save again to proceed."
                                )
                                setSaveWarning(true)
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "update")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Save</Button>
                    <Button className="blue-button"
                        onClick={() => {
                            if (planTypeUseCount > 0) {
                                setTitleMessage("You cannot delete this pricing plan type")
                                setValidationMessage("This pricing plan type is currently in referenced in: " + planTypeUseCount + " pricing plan(s)."
                                + " To delete it you need to first update the plans that include this plan type with a new plan type.  "
                                + "You can do this by accessing the Plan Details dropdown menu item."
                                )
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "delete")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Delete</Button>
                    </>
                    }
                    {(props.CRUD === "Add") &&
                    <Button className="blue-button"
                        onClick={() => {
                            setValue("formAction", "insert")
                            handleSubmit(onSubmit)()
                        }}
                    >Save</Button>
                    }
                    </Stack>
                    </>
                    <p>* Required field</p>
            </Row>
            {(!dbWorkComplete) &&
                    <div>
                    <Spinner animation="border" role="status" style={{color: "#00447c"}}>
                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    </div>
            }
        </form>
    </Container>
    </div>

    )

}

export default PricingPlanTypeFormCRUD