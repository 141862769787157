// thanks to tannerlinsley / react-table / master /examples/virtualized-rows
import React from "react";
import { useTable, useGlobalFilter, useFlexLayout, useResizeColumns, useRowSelect } from 'react-table'
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { FixedSizeList } from 'react-window'
import {Button } from "react-bootstrap"
import setDate from "../utils/setDate";
import WriteData from "../utils/WriteData";


const DataExportTable = ({
    columns,
    data,
    caller,
    setRefresh,
    refresh,
    setDataChange,
    handleOuterClose
  }) => {

  console.log ("DataExportTable data= ", data)
  console.log ("DataExportTable caller=", caller)
  console.log ("DataExportTable refresh=", refresh)
  console.log ("DataExportTable setRefresh typeof=", typeof setRefresh)


  // const [refreshTable, setRefreshTable] = useState(false)
  let insertClientMasterBillingPath = ""
  let updateClientMasterBillingPath = ""
  let insertClientMasterBillingArray = []
  let updateClientMasterBillingArray = []
  let insertClientMasterBillingFlag = false
  let updateClientMasterBillingFlag = false

  // const handleClose = () => setShow(false);

  // extractData produces CSV files and updates the database to record extracts

  function extractData (rows, handleOuterClose) {

    const REACT_APP_ENDPOINT_ACCOUNTING=process.env.REACT_APP_ENDPOINT_ACCOUNTING;
    insertClientMasterBillingPath = REACT_APP_ENDPOINT_ACCOUNTING + "/insert_client_master_export"
    updateClientMasterBillingPath = REACT_APP_ENDPOINT_ACCOUNTING + "/update_client_master_export"

    console.log("DataExportTable extractData display data=", rows)
    // prepare data for extract and database logging

    if (caller === "Client Billing Master Export") {

      for (let i = 0; i < rows.length; i++) {

        rows[i].values.export_date = setDate ()
        rows[i].original.export_date = setDate()

          if (rows[i].original.community_id === null) {
            insertClientMasterBillingFlag = true
            rows[i].original.community_id = rows[i].original.community_community_id
            rows[i].original['createdBy'] = window.sessionStorage.getItem('userId')
            insertClientMasterBillingArray.push(rows[i].original)
            }
          else {
            updateClientMasterBillingFlag = true
            rows[i].original['updatedBy'] = window.sessionStorage.getItem('userId')
            updateClientMasterBillingArray.push(rows[i].original)
          }
        }
    }
        // export to CSV file
        exportData("csv", false)

        console.log("DataExportTable insertClientMasterBillingArray=", insertClientMasterBillingArray)
        if (insertClientMasterBillingFlag === true ) {
        WriteData(
            insertClientMasterBillingPath,
            insertClientMasterBillingArray,
            "post",
            null,  // not tracking DB completion
            null,  //key value is taken are of
            null,  // don't need a refresh
            null
        )
        insertClientMasterBillingFlag = false
    }
    console.log("DataExportTabe updateClientMasterBillingArray=", updateClientMasterBillingArray)
        if (updateClientMasterBillingFlag === true ) {
        WriteData(
            updateClientMasterBillingPath,
            updateClientMasterBillingArray,
            "put",
            null,  // not tracking DB completion
            null,  //key value is taken are of
            null,  //don't need a refresh
            null,
        )
        updateClientMasterBillingFlag = false
    }
        handleOuterClose()
  }

  function getExportFileBlob({ columns, data, fileType, fileName }) {

    let title = caller + " - " + setDate()

    if (fileType === "csv") {
      // CSV export
      const headerNames = columns.map((col) => col.exportValue);
      // const csvString = title+'\n\n'+Papa.unparse({ fields: headerNames, data });
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    }
    //PDF export
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF('l');
      doc.autoTable({
        setFontSize: 20,
        setTextColor: 40,
        startY: 30,
        head: [headerNames],
        body: data,
        margin: { top: 30 },
        styles: {
          minCellHeight: 9,
          halign: "left",
          valign: "center",
          fontSize: 11,
          showHead: "everyPage",
          overflow: "linebreak"
        },
        didDrawPage: function (data) {

          // Header
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.text(title, data.settings.margin.left, 22);

          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();

          doc.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
        }
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }



  // Use the state and functions returned from useTable to build UI

//   const defaultColumn = React.useMemo(
//     () => ({
//       width: 150,
//     }),
//     []
//   )

    const defaultColumn = React.useMemo(
        () => ({
        // When using the useFlexLayout:
        minWidth: 30, // minWidth is only used as a limit for resizing
        width: 150, // width is used for both the flex-basis and flex-grow
        maxWidth: 200, // maxWidth is only used as a limit for resizing
        }),
        []
    )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getExportFileBlob,
      initialState: {
        hiddenColumns: [""],
      },
    },
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    useGlobalFilter,
    useExportData
  )

  const handleFilterInputChange = (event) => {
    const { value } = event.currentTarget;
    setGlobalFilter(value);
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr"
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows]
  )

//   // used by other UEBA tables.
//   const average = React.useMemo(
//     () =>
//         rows.reduce((sum, row) => Number(row.values.average_rating) + sum, 0) / rows.length,
//     [rows]
//   )

  // Render the UI for your table
  return (

    <div className=" align-item-center justify-content-center">

    <div className="d-flex align-items-center justify-content-center"
        style={{
        marginTop: "-30px",
        marginBottom: "30px",
        }}
        >

    <Button className="blue-button"
        style={{
          color: 'white',
        }}
        onClick={() => {

          if (caller === "Client Billing Master Export") {
            console.log("DataExportTable calling dataExtract")
            extractData (rows, handleOuterClose)
            setDataChange(false)
          }
          else {
            exportData("csv", false)
            setDataChange(false)
            console.log("DataExportTable doing simple export")
          }
        }}
    >
        Export Current View as CSV
    </Button>
    {(caller !== "Client Billing Master Export") &&
        <Button className="blue-button"
            style={{
            color: 'white',
            }}
            onClick={() => {
            exportData("pdf", false);
            setDataChange(false)
            }}
        >
            Export Current View as PDF
        </Button>
    }
    </div>

    {/* <Row className="text-center align-items-center justify-content-center"><Col> */}
    <input placeholder="Enter filter value"
        style={{
            height: "40px",
            width: "100%",
            display: "inline-flex",
            // flex: "1",
            textAlign: "center",
            border: "1px solid #ccc",
            // marginTop: "-300px",
            marginBottom: "0px"}}
        onChange={handleFilterInputChange} />
    <br/>
    {/* </Col>
    </Row> */}



    <div {...getTableProps()} className="table">

      {/* <InformationModal
            show={show}
            handleClose={handleClose}
            caller={caller}
            formData={data}
            selectedValue={valueSelected}
            modalType={modalType}
        /> */}
      <div>
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps()} className="th">
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        <FixedSizeList
          height={600}
          itemCount={rows.length}
          itemSize={35}
        //   width={totalColumnsWidth+scrollBarSize}
        >
          {RenderRow}
        </FixedSizeList>
      </div>
    </div>
    </div>





  )
}



export default DataExportTable