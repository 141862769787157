// thanks to tannerlinsley / react-table / master /examples/virtualized-rows
import React from "react";
import { useTable, useGlobalFilter, useBlockLayout } from 'react-table'
import { FixedSizeList } from 'react-window'
import scrollbarWidth from '../utils/scrollbarWidth'
import InformationModal from "./InformationModal";
import ModalContainer from "./ModalContainer";


const QueryTable = ({
    columns,
    data,
    caller,
    refresh,
    setRefresh,
    show,
    setShow,
    valueSelected,
    setValueSelected,
    modalType }) => {

  console.log ("Table data= ", data)
  console.log ("caller=", caller)

  // apply fixed format to average host ratings - used by Sponsored Host Ratings

  function toFixedFormat (value) {
    return parseFloat(value).toFixed(2)
  }

  const handleClose = () => setShow(false);

  // Use the state and functions returned from useTable to build UI

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  )

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useGlobalFilter
  )

  const handleFilterInputChange = (event) => {
    const { value } = event.currentTarget;
    setGlobalFilter(value);
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr"
            onClick={() => {
              if (caller === "Client Pricing Plans" ) {
                setValueSelected(row.original.pricing_plan_id)
                console.log("QueryTable onclick valueSelected=", valueSelected)
                setShow(true)
            }
            }}
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows]
  )

  // used by Sponsored Host Ratings
  const average = React.useMemo(
    () =>
        rows.reduce((sum, row) => Number(row.values.average_rating) + sum, 0) / rows.length,
    [rows]
  )

  // Render the UI for your table
  return (
    <div>

    {(caller === "Sponsored Host Ratings"  || caller === "Sponsored Host Game Schedule") &&
      <div className="d-flex justify-content-center top-padding">
        <span className="filtered-total">
          {(caller === "Sponsored Host Ratings") &&
            <>
            {/* Average Rating:  {toFixedFormat(average)} */}
            Average Rating:  {toFixedFormat(isNaN(average) ? 0 : average)}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </>
          }
          Hosted Games:  {rows.length}
        </span>
        <br/><br/>
      </div>
    }
    <input placeholder="Enter filter value"
        style={{height: "40px",
            width: "1168px",
            textAlign: "center",
            border: "1px solid #ccc",
            marginTop: "-400px",
            marginBottom: "10px"}}
        onChange={handleFilterInputChange} />
    <br/>

    <div {...getTableProps()} className="table">

      {(caller === "Client Pricing Plans") &&
      <ModalContainer
            show={show}
            handleClose={handleClose}
            caller={caller}
            formData={data}
            selectedValue={valueSelected}
            CRUD={"ReadUpdateDelete"}
            setRefresh={setRefresh}
            refresh={refresh}
        />
      }

      {(caller !== "Client Pricing Plans") &&
      <InformationModal
            show={show}
            handleClose={handleClose}
            caller={caller}
            formData={data}
            selectedValue={valueSelected}
            modalType={modalType}
        />
      }

      <div>
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps()} className="th">
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        <FixedSizeList
          height={500}
          itemCount={rows.length}
          itemSize={35}
          width={totalColumnsWidth+scrollBarSize}
        >
          {RenderRow}
        </FixedSizeList>
      </div>
    </div>
    </div>





  )
}



export default QueryTable