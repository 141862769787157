import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Button, Stack, Spinner} from "react-bootstrap"
import MessageModal from "./MessageModal"
import doValidations from "../utils/doValidations";
import doValidationLookup from "../utils/doValidationLookup";
import ValidationQuery from "../utils/ValidationQuery"
import WriteData from "../utils/WriteData";
import "./Form.css"

const PricingPlanFormCRUD = (props) => {

    // NOTE:  This form only allows inserts, updates and deletes by user with UE SuperAdmin privs.
    let Authorized = false

    if (window.sessionStorage.getItem('roleId') === '1' ) {
        Authorized = true
    }
    console.log("Authorized=", Authorized)

    console.log("in PricingPlanFormCrud")
    const setDataChange = props.setDataChange // descructure for useEffect
    // console.log("props.setRefresh=", props.setRefresh)
    // console.log("PricingPlanFormCrud formData= ", props.formData)
    // console.log("PricingPlanFormCRUD sectionValue = ", props.zoomAccountUserId)

    const REACT_APP_ENDPOINT_ZOOM=process.env.REACT_APP_ENDPOINT_ZOOM;
    let apiPath = ''

    const [dbWorkComplete, setDbWorkComplete] = useState(true);
    const [postDelete, setPostDelete] = useState(false);  //to prevent a preLoadValues load in rerender after record deletion.

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [titleMessage, setTitleMessage] = useState(null)
    const [validationMessage, setValidationMessage] = useState(null)

    const [deleteWarning, setDeleteWarning] = useState(false)
    const [validationLookup, setValidationLookup] = useState(false)

    let gameScheduleCount = 0
    gameScheduleCount = ValidationQuery('/scheduled_games_zoom', props.zoomAccountUserId, "games")
    console.log ("*****gameScheduleCount=", gameScheduleCount)

    let preloadValues = {}

    const onSubmit = async data => {
        console.log(data);
        setDbWorkComplete(false)
        console.log ("formAction=", getValues("formAction"))
        let passValidation = true
        if (getValues("formAction") === "insert" || getValues("formAction") === "update") {
            passValidation = await doValidations("PricingPlanFormCRUD", getValues("formAction"), dirtyFields, data, props.formData, setValidationMessage)
        }
        if (passValidation) {
            if (getValues("formAction") === "insert") {
                apiPath=REACT_APP_ENDPOINT_ZOOM + "/zoom_license_insert"
                WriteData(apiPath, data, "post", setDbWorkComplete)
            }
            else if (getValues("formAction") === "update") {
                apiPath=REACT_APP_ENDPOINT_ZOOM + "/zoom_license_update"
                WriteData(apiPath, data, "put", setDbWorkComplete, props.zoomAccountUserId, props.refresh, props.setRefresh)
                props.handleOuterClose()
            }
            else if (getValues("formAction") === "delete") {
                apiPath=REACT_APP_ENDPOINT_ZOOM + "/zoom_license_update"
                WriteData(apiPath, data, "delete", setDbWorkComplete, props.zoomAccountUserId,  props.refresh, props.setRefresh)
                setPostDelete(true)
                setDbWorkComplete(true)
                props.handleOuterClose()
            }
            if (dbWorkComplete) {
                console.log ("back in PricingPlanFormCrud dbWorkComplete")
                reset({
                    zoomUserName: "",
                    zoomUserId: "",
                    zoomUserEmail: "@utopiaexperiences.net",
                    accessKey: " ",
                    secretKey: " "
                    },
                    {
                        keepDefaultValue: true,
                    })
                props.setDataChange(false)
             }
        }
        else {
            console.log('validationMessage=', validationMessage)
            setTitleMessage("There's a data issue")
            setDbWorkComplete(true)
            setShow(true)
        }
    }

    // console.log("in to PricingPlanFormCRUD dataChange= ", props.dataChange)

    if (props.CRUD === "ReadUpdateDelete" && !postDelete) {
         let formValues = props.formData.filter(obj => {
                return obj.zoom_account_user_id === props.zoomAccountUserId
        })
        console.log("PricingPlanFormCRUD formValue= ", formValues)
        preloadValues = {
            zoomUserName: formValues[0].zoom_user_name,
            zoomUserId: formValues[0].zoom_user_id,
            zoomUserEmail: formValues[0].zoom_user_email,
            accessKey: formValues[0].access_key,
            secretKey: formValues[0].secrete_key,
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
    }
    else {
        preloadValues = {
            zoomUserName: "",
            zoomUserId: "",
            zoomUserEmail: "@utopiaexperiences.net",
            accessKey: " ",
            secretKey: " ",
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
        }

    const { register, handleSubmit, watch, getValues, setValue, reset, formState: {dirtyFields}} = useForm({
        defaultValues: preloadValues
    });

    // watch tracks user input
    watch ()

    useEffect(() => {
        const subscription = watch(() => setDataChange(true))
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
    <div className="form-box">
    <Container fluid className="form-box">
        <MessageModal
            show={show}
            handleClose={handleClose}
            titleMessage={titleMessage}
            validationMessage={validationMessage}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
            {(gameScheduleCount > 0) &&
                <Container fluid>
                <Row display="table" className="align-items-center justify-content-center">
                    <Col md="auto" className="orange-text" >In Use</Col>
                </Row>
                <br/><br/>
                </Container>
            }
            <Row>
            <Col >
                <label className="label-format">PricingPlan User Id*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="PricingPlan User ID" className="input-format"
                {...register("zoomUserId", {required: true})} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">PricingPlan User Email*</label>
            </Col>
            <Col xs={9}>
                <input type="email" placeholder="PricingPlan User Email" className="input-format"
                {...register("zoomUserEmail", {required: true, pattern: /^\S+@\S+$/i})} />
            </Col>
            </Row>
            <br/>
            <Row >
            <Col >
                <label className="label-format">PricingPlan User Name</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="PricingPlan User Name" className="input-format"
                {...register("zoomUserName", {required: false})} />
            </Col>
            </Row>
            <br/>

            {/* Commented out when it was deemed unnecessary due to vendor change */}

            {/* <Row>
            <Col >
                <label className="label-format">Access Key</label>
            </Col>
            <Col xs={9}>
                <textarea readOnly type="text" disabled="disabled" placeholder="Access Key" className="input-format"
                {...register("accessKey", { max: 500, maxLength: 2000})} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Secret Key</label>
            </Col>
            <Col xs={9}>
                <textarea readOnly type="text" disabled="disabled" placeholder="Secret Key" className="input-format"
                {...register("secretKey", { max: 500, maxLength: 2000})} />
            </Col>
            </Row>
            <br/> */}

            {/* conditionally render CRUD buttons based on form mode. */}

            <Row className="justify-content-center">

                    <>
                    <Stack direction="horizontal" gap={3} className="d-flex justify-content-center">
                    {(validationLookup === false) &&
                    <Button className="yellow-button"
                        onClick={() => {
                            doValidationLookup(
                                '/verify_zoom_user', getValues("zoomUserEmail"), "zoom", setValidationLookup,
                                setShow, setTitleMessage, setValidationMessage)
                            console.log ("validationLookup =", validationLookup)
                         }}
                    >Validate</Button>
                    }
                    {(validationLookup === true) &&
                        <Button className="blue-button"
                        onClick={() => {
                            doValidationLookup(
                                '/verify_zoom_user', getValues("zoomUserEmail"), "zoom", setValidationLookup,
                                setShow, setTitleMessage, setValidationMessage)
                            console.log ("validationLookup =", validationLookup)
                         }}
                    >Validate</Button>
                    }
                    {(props.CRUD === "ReadUpdateDelete" && Authorized === true) &&
                    <>
                    <Button className="blue-button"
                        onClick={() => {
                            if (validationLookup === false) {
                                setTitleMessage ("Please validate the PricingPlan license")
                                setValidationMessage(" ")
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "update")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Save</Button>
                    <Button className="blue-button"
                        onClick={() => {
                            if (deleteWarning === false) {
                                setTitleMessage("You are about to delete a PricingPlan license")
                                if (gameScheduleCount === 0) {
                                    setValidationMessage("PricingPlan licenses impact critical game scheduling and play.  "
                                    + " While this license does not show as being in use, it is strongly recommended that "
                                    + "you double check the game_schedule table after this operation to ensure the license hasn't "
                                    + "been used by a client as you're reading this.  Please be certain you want to delete this license.")
                                }
                                else {
                                    setValidationMessage("PricingPlan licenses impact critical game scheduling and play.  "
                                    + "This license is actively in use for " + gameScheduleCount + " scheduled game(s). "
                                    + "Please be certain you want to delete this license.  If you wish fully disable use of this "
                                    + "license for the scheduled games, you must do so through PricingPlan.")
                                }
                                setShow(true)
                                setDeleteWarning(true)
                            }
                            else {
                                setValue("formAction", "delete")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Delete</Button>
                    </>
                    }
                    {(props.CRUD === "Add" && Authorized === true) &&
                    <Button className="blue-button"
                            onClick={() => {
                            if (validationLookup === false) {
                                setTitleMessage ("Please validate the PricingPlan license")
                                setValidationMessage(" ")
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "insert")
                                handleSubmit(onSubmit)()
                            }

                         }}
                    >Save</Button>
                    }
                    </Stack>
                    </>
                    <p>* Required field</p>
            </Row>
            {(!dbWorkComplete) &&
                    <div>
                    <Spinner animation="border" role="status" style={{color: "#00447c"}}>
                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    </div>
            }
        </form>
    </Container>
    </div>

    )

}

export default PricingPlanFormCRUD