import React, { useState, useMemo} from "react";
import styled from 'styled-components'
import QueryTable from "./QueryTable";

const HostRatingTable = (props) => {

  const [show, setShow] = useState(false);
  const [valueSelected, setValueSelected] = useState({})
  const [modalType, setModalType] = useState()

    // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;

        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                ${'' /* border-bottom: 0; */}
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }


          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }
        }
      `

  // apply fixed format to host ratings

  function toFixedFormat (value) {
    return parseFloat(isNaN(value) ? 0 : value).toFixed(2)
  }

  // simple array to map columns for table.


  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [
          {
            Header: "Date",
            accessor: "game_date",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.game_date} </div>
            )
            }
          },
          {
            Header: "Game",
            accessor: "game_name",
            width: 180,
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.game_name} </div>
            )
            }
          },
          {
            Header: "Community",
            accessor: "community_name",
            width: 355,
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.community_name} </div>
            )
            }
          },
          {
            Header: "Host",
            accessor: "host_name",
            width: 200,
            Cell: ({row}) => {
                const dataValue = row.original
                const showHostInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("host contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showHostInformation()} > {dataValue.host_name} </div>
            )
            }
          },
          {
            Header: "Engage.",
            accessor: "host_engagement_rating",
            width: 94,
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div style={{ textAlign: "right" }} onClick={()=> showRatingInformation()} > {toFixedFormat(dataValue.host_engagement_rating)} </div>
            )
            }
          },
          {
            Header: "Compet.",
            accessor: "host_competence_rating",
            width: 94,
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div style={{ textAlign: "right" }} onClick={()=> showRatingInformation()} > {toFixedFormat(dataValue.host_competence_rating)} </div>
            )
            }
          },
          {
            Header: "Average",
            accessor: "average_rating",
            width: 94,
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div style={{ textAlign: "right" }} onClick={()=> showRatingInformation()} > {toFixedFormat(dataValue.average_rating)} </div>
            )
            }
          },
        ]
      },
    ],
    []
  );
  return (
      <Wrapper>
        <Styles>
            <QueryTable
              columns={columns}
              data={props.data}
              caller={props.caller}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
              setShow={setShow}
              show={show}
              valueSelected={valueSelected}
              modalType={modalType}
               />
        </Styles>
    </Wrapper>
  );
}

export default HostRatingTable;