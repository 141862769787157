import React, { useMemo } from "react";
import styled from 'styled-components'
import DataExportTable from "./DataExportTable";
import setDate from "../utils/setDate";

const ClientBillingMasterExport = (props) => {

   // doing something different with the table.  See comments

  console.log("ClientBillingMasterExport props.refesh=", props.refresh)
  console.log("ClientBillingMasterExport props.refesh=", typeof props.setRefresh)
  console.log("ClientBillingMasterExport props.data=", props.data)

  // Depending on path of access, we will be reading data from session storage to populate
  // the table.  So, we need a place to override what's passed in from ModalContainer for
  // one use case / path.  Create data array to store the override

  let data = []

  // preprocessing...
  // ...CRUD form and user wants to review singleton data for export --which it means the row
  // came from a Form rather than a DB call, which means remapping.  Ugh.  Camelcase for JS is
  // great but I've become something of a slave to it.  What's coming from session storage
  // is the result of a DB call.  All of our tables are geared to accept data directly from the
  // database, which is why it's necessary to do a translation here.

  if (typeof props.data.length === "undefined") {
    // determine the source of the data.  A translation needs to be done to SQL standard.
    //Forms pass single objects direcly not in array format.

    let billing_contact_name = props.data["firstName"] + " " + props.data['lastName']
    let dataList = {
      community_id: props.data["communityId"],
      client_billing_name: billing_contact_name,
      community_name: props.data["communityName"],
      payment_method: props.data["paymentMethod"],
      delivery_method: props.data["deliveryMethod"],
      email_id: props.data["emailId"],
      billing_email: props.data["billingEmail"],
      office_phone: props.data["officePhone"],
      phone_number: props.data["phoneNumber"],
      web_site: props.data["webSite"],
      street_address: props.data["streetAddress"],
      city: props.data["city"],
      state: props.data["state"],
      zip_postal: props.data["zipPostal"],
      country: props.data["country"],
      date_joined: props.data["dateJoined"],
      // set export_date = today since user is about to export and desires for that to appear in output
      export_date: setDate()
    }

    data.push(dataList)
    console.log("clientBillingMasterExport data=", data)

  }
  else {
    data = JSON.parse(sessionStorage.getItem("currentData"))
    // this table will trigger a refresh if it writes data.  Session storage gets cleared
    for (let i = 0; i < data.length; i++) {
      // set export_date = today since user is about to export and desires for that to appear in output
        data[i].export_date = setDate()
      }
    console.log("-----------data=", data)
  }

    // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;
        .rt-thead {
            overflow-y: scroll;
        }
        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                ${'' /* border-bottom: 0; */}
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }


          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }
        }
      `



  // simple array to map columns for table.


  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [
          {
            Header: "Billing Contact",
            accessor: "client_billing_name",
          },
          {
            Header: "Company",
            accessor: "community_name",
            width: 300,
          },
          {
            Header: "Pref. Payment",
            accessor: "payment_method",
          },
          {
            Header: "Inv. Delivery",
            accessor: "delivery_method",
          },
          {
            Header: "Contact Email",
            accessor: "email_id",
          },
          {
            Header: "Billing Email",
            accessor: "billing_email",
          },
          {
            Header: "Office Phone",
            accessor: "office_phone",
          },
          {
            Header: "Contact Phone",
            accessor: "phone_number",
          },
          {
            Header: "Website",
            accessor: "web_site",
          },
          {
            Header: "Street",
            accessor: "street_address",
          },
          {
            Header: "City",
            accessor: "city",
          },
          {
            Header: "State",
            accessor: "state",
          },
          {
            Header: "Zip",
            accessor: "zip_postal",
          },
          {
            Header: "Country",
            accessor: "country",
          },
          {
            Header: "Joined",
            accessor: "date_joined",
          },
          {
            Header: "Export Date",
            id: "export_date",
            accessor: "export_date",
          },
        ]
      },
    ],
    []
  );
  return (
      <Wrapper>
        <Styles>
            <DataExportTable
              columns={columns}
              data={data}
              caller={"Client Billing Master Export"}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
              setDataChange={props.setDataChange}
              handleOuterClose={props.handleOuterClose}
               />
        </Styles>
    </Wrapper>
  );
}

export default ClientBillingMasterExport;